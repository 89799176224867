/* eslint-disable jsx-a11y/alt-text */
import { MetaFunction } from "@remix-run/cloudflare";
import { Menu, Plus, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { motion } from "motion/react";
import { Separator } from "app/components/ui/separator";
import { useEffect, useState } from "react";

export const meta: MetaFunction = () => {
  return [
    { title: "PunchPocket" },
    {
      name: "description",
      content: "PunchPocket: Your Loyalty, Simplified.",
    },
  ];
};

function Carousel({ images }: { images: { name: string; src: string }[] }) {
  return (
    <div className="relative w-full overflow-hidden">
      <motion.div
        animate={{
          x: ["0%", "-50%"],
        }}
        transition={{
          duration: 50,
          ease: "linear",
          repeat: Infinity,
        }}
        className="flex min-w-max py-4"
      >
        {/* First set of images */}
        {images.map((image) => (
          <div
            key={image.name}
            className="w-44 flex justify-center items-center shrink-0"
          >
            <img
              src={image.src}
              alt={image.name}
              className="w-full p-5 object-contain grayscale transition-all duration-300 hover:grayscale-0 hover:saturate-200 contrast-200 hover:contrast-100 brightness-0 hover:brightness-100"
            />
          </div>
        ))}
        {/* Duplicate set for seamless loop */}
        {images.map((image) => (
          <div
            key={`${image.name}-duplicate`}
            className="w-44 flex justify-center items-center shrink-0"
          >
            <img
              src={image.src}
              alt={image.name}
              className="w-full p-5 object-contain grayscale transition-all duration-300 hover:grayscale-0 hover:saturate-200 contrast-200 hover:contrast-100 brightness-0 hover:brightness-100"
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default function LandingPage() {
  const carousel = [
    {
      name: "Portoberry",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/d0200165-4189-405d-36fc-6f16de7b6b00/public",
    },
    {
      name: "Beit Cafe",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/c7f26cc1-2545-4e0c-ad5e-d9d1ee0efe00/public",
    },
    {
      name: "Tudor Green",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/05c3a4d6-707d-486f-3448-4abb9d4ed700/public",
    },
    {
      name: "La Brioche",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/41b2a96f-070e-4481-6f89-cdd72fe82000/public",
    },
    {
      name: "Mr Baker",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/8cf3aabc-5732-41fb-a636-4f94e6af5900/public",
    },
    {
      name: "Mr Neapolitan",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/deecb514-498f-46ca-2573-45066f75aa00/public",
    },
    {
      name: "The Warehouse",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/90fd8d14-2b1b-473e-4df3-3713751c8600/public",
    },
    {
      name: "Mr Falafel",
      src: "https://imagedelivery.net/nmXa3uJQUBrgBTbhJ9cGeA/0eb50185-3f79-475c-a617-586841721f00/public",
    },
  ];

  return (
    <>
      <TitleBar />
      <header className="mt-16 max-w-6xl mx-auto">
        <div className="text-center text-balance text-4xl font-bold mt-10">
          Digital{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="inline-block w-9 h-9 -mt-1"
          >
            <rect
              x="5"
              y="2"
              width="14"
              height="20"
              rx="2"
              fill="none"
              stroke="#4A90E2"
              strokeWidth="2.5"
            />
            <line
              x1="5"
              y1="18"
              x2="19"
              y2="18"
              stroke="#4A90E2"
              strokeWidth="2.5"
            />
            <circle cx="12" cy="20" r="1" fill="#4A90E2" />
          </svg>{" "}
          Loyalty That Drives Real{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="inline-block w-9 h-9 -mt-1"
          >
            <path
              fill="#2ECC71"
              d="M3 3v16a2 2 0 002 2h16v-2H5V3H3z"
              strokeWidth="3"
            />
            <path
              fill="#2ECC71"
              d="M21 6l-7 7-4-4-6 6 1.4 1.4 4.6-4.6 4 4L21 8.4V6z"
              strokeWidth="3"
            />
          </svg>{" "}
          ROI
        </div>
        <div className="text-center text-balance text-lg font-light opacity-70 mt-4">
          Cut costs, boost customer retention, and track performance in
          real-time.
        </div>

        {/* CTA Section: */}
        <div className="flex justify-center gap-4 mt-8">
          <a
            href="mailto:hello@punchpocket.com"
            className="cursor-pointer bg-gradient-to-r from-purple-900 to-purple-700 text-white font-semibold px-6 py-3 rounded-lg shadow-lg shadow-purple-900/30 hover:shadow-purple-900/40 hover:-translate-y-0.5 transition-all inline-block"
          >
            Get Started
          </a>

          <a
            href="mailto:hello@punchpocket.com"
            className="cursor-pointer bg-white/10 backdrop-blur border-2 border-purple-900/20 text-purple-900 font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-purple-50 hover:border-purple-900/30 transition-all inline-block"
          >
            Book Demo
          </a>
        </div>
      </header>

      <HeroSection />

      <main className="mt-16 px-7 sm:px-24 md:px-40 space-y-16 max-w-6xl mx-auto">
        <div className="space-y-6">
          <p className="rounded-full bg-yellow-300 text-black w-min text-base px-2.5 py-0.5 font-semibold">
            Business
          </p>
          <div className="-space-y-1">
            <p className="font-semibold text-5xl">Real results,</p>
            <p className="font-semibold text-5xl">real growth.</p>
          </div>
          {/* Cards */}
          <div className="space-y-10">
            <div className="bg-slate-200 rounded-xl overflow-clip lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
              <div className="p-8 mt-12 lg:mt-0">
                <p className="font-semibold text-3xl">
                  Boost Customer Lifetime Value.
                </p>
                <p className="text-base opacity-70 mt-6">
                  Turn first-time visitors into loyal regulars with engaging
                  rewards. Track and optimize your loyalty program to maximize
                  customer retention.
                </p>
              </div>
              <img
                src="https://i.imgur.com/uJkdUiX.png"
                alt="Boost Customer Lifetime Value"
                className="object-contain w-full translate-y-20 -mt-20 lg:p-10 lg:w-1/2"
              />
            </div>

            <div className="gap-y-10 gap-x-8 flex flex-col lg:flex-row">
              <Dialog>
                <DialogTrigger asChild className="lg:w-7/12">
                  <div className="bg-yellow-100 rounded-xl flex flex-col justify-between min-h-[50vh] py-8">
                    <p className="text-lg font-medium opacity-70 px-8">
                      Network
                    </p>
                    <div className="space-y-4">
                      <p className="text-4xl opacity-70 text-center font-semibold text-balance">
                        Join Growing Businesses.
                      </p>
                      <div className="opacity-60">
                        <Carousel images={carousel} />
                      </div>
                    </div>
                    <div className="place-self-end flex justify-end w-full px-8">
                      <button className="bg-background rounded-full p-1.5">
                        <Plus
                          className="w-7 h-7 text-yellow-800"
                          enableBackground="green"
                        />
                      </button>
                    </div>
                  </div>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <DialogHeader>
                    <DialogTitle className="text-3xl">Network</DialogTitle>
                    <DialogDescription className="text-2xl h-full flex justify-center items-center">
                      Thousands of customers already have PunchPocket on their
                      phones. Let them start earning rewards at your business
                      instantly—no convincing needed.
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>

              <Dialog>
                <DialogTrigger asChild className="lg:w-5/12">
                  <div className="bg-amber-200 rounded-xl flex flex-col justify-between p-8 space-y-10">
                    <p className="text-lg font-medium opacity-70">Data</p>
                    <div className="space-y-8">
                      <p className="text-4xl opacity-70 text-center font-semibold text-balance">
                        See your success in real-time
                      </p>
                      <div className="opacity-70">
                        <span className="relative justify-self-end flex size-10 lg:size-7">
                          <span className="top-5 left-2 absolute inline-flex h-full w-full animate-ping rounded-full bg-orange-400 opacity-75"></span>
                          <span className="top-5 left-2 relative inline-flex size-10 lg:size-7 rounded-full bg-orange-500"></span>
                        </span>
                        <img
                          src="https://i.imgur.com/0ehdf0L.png"
                          alt="See your success in real-time"
                          className="w-full rounded-3xl lg:rounded-xl"
                        />
                      </div>
                    </div>
                    <div className="place-self-end flex justify-end w-full">
                      <button className="bg-background rounded-full p-1.5">
                        <Plus
                          className="w-7 h-7 text-amber-900"
                          enableBackground="green"
                        />
                      </button>
                    </div>
                  </div>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <DialogHeader>
                    <DialogTitle className="text-3xl">Data</DialogTitle>
                    <DialogDescription className="text-2xl h-full flex justify-center items-center">
                      Track every punch, redemption, and customer trend from one
                      dashboard. Make data-driven decisions that boost your
                      bottom line.
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

        <div className="space-y-6 scroll-mt-28" id="features">
          <p className="rounded-full bg-purple-300 text-black w-min text-base px-2.5 py-0.5 font-semibold">
            Platform
          </p>
          <div className="-space-y-1">
            <p className="font-semibold text-5xl">Three apps,</p>
            <p className="font-semibold text-5xl">one solution.</p>
          </div>

          {/* Cards */}
          <div className="space-y-10">
            <Dialog>
              <DialogTrigger asChild>
                <div className="bg-purple-100 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                  <p className="text-lg font-medium opacity-70 px-8 lg:hidden">
                    Customers
                  </p>
                  <div className="space-y-4 lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
                    <div>
                      <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8 lg:text-left">
                        One PunchCode for Everything.
                      </p>
                      <p className="text-xl opacity-40 font-medium text-balance px-8 lg:text-left hidden lg:block mt-4">
                        Customers use a single QR code that works at every
                        partnered business. No app downloads required—they can
                        add their code to Apple/Google Wallet for instant
                        access.
                      </p>
                    </div>
                    <div className="p-10 w-full">
                      <div
                        style={{
                          position: "relative",
                          paddingTop: "136%",
                        }}
                        className="rounded-xl overflow-clip shadow-2xl shadow-purple-900"
                      >
                        <iframe
                          title="PunchPocket Demo"
                          src="https://customer-pbblgh03drve2rrb.cloudflarestream.com/02fc25acee1fedb40a40774ace77b4d3/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-pbblgh03drve2rrb.cloudflarestream.com%2F02fc25acee1fedb40a40774ace77b4d3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                          loading="lazy"
                          style={{
                            border: "none",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            height: "100%",
                            width: "100%",
                          }}
                          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div className="place-self-end flex justify-end w-full px-8">
                    <button className="bg-background rounded-full p-1.5 lg:hidden">
                      <Plus
                        className="w-7 h-7 text-purple-800"
                        enableBackground="green"
                      />
                    </button>
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="rounded-3xl">
                <DialogHeader>
                  <DialogTitle className="text-3xl">Customers</DialogTitle>
                  <DialogDescription className="text-2xl h-full flex justify-center items-center">
                    Customers use a single QR code that works at every partnered
                    business. No app downloads required—they can add their code
                    to Apple/Google Wallet for instant access.
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>

            <div className="gap-y-10 gap-x-8 flex flex-col lg:flex-row">
              <Dialog>
                <DialogTrigger asChild className="lg:w-7/12">
                  <div className="bg-purple-300 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                    <p className="text-lg font-medium opacity-70 px-8">
                      Business
                    </p>
                    <div className="space-y-4">
                      <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                        Complete Control Center.
                      </p>
                      <div className="p-10">
                        <img
                          src="https://i.imgur.com/57uVLbt.png"
                          alt="Complete Control Center"
                          className="w-full rounded-lg shadow-2xl shadow-purple-900"
                        />
                      </div>
                    </div>
                    <div className="place-self-end flex justify-end w-full px-8">
                      <button className="bg-background rounded-full p-1.5">
                        <Plus
                          className="w-7 h-7 text-purple-900"
                          enableBackground="green"
                        />
                      </button>
                    </div>
                  </div>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <DialogHeader>
                    <DialogTitle className="text-3xl">Business</DialogTitle>
                    <DialogDescription className="text-2xl h-full flex justify-center items-center">
                      Manage your entire loyalty program from one secure
                      dashboard. Create campaigns, track performance, and adjust
                      rewards instantly.
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>

              <Dialog>
                <DialogTrigger asChild className="lg:w-5/12">
                  <div className="bg-violet-200 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                    <p className="text-lg font-medium opacity-70 px-8">Staff</p>
                    <div className="space-y-4">
                      <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                        Scan, Punch, Done.
                      </p>
                      <div className="p-10">
                        <Scanner />
                      </div>
                    </div>
                    <div className="place-self-end flex justify-end w-full px-8">
                      <button className="bg-background rounded-full p-1.5">
                        <Plus
                          className="w-7 h-7 text-violet-700"
                          enableBackground="green"
                        />
                      </button>
                    </div>
                  </div>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <DialogHeader>
                    <DialogTitle className="text-3xl">Staff</DialogTitle>
                    <DialogDescription className="text-2xl h-full flex justify-center items-center">
                      Staff simply scan customer codes to award punches or
                      redeem rewards. No training needed—if they can use a
                      phone, they can use PunchPocket.
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <p className="rounded-full bg-blue-200 text-black w-min text-base px-2.5 py-0.5 font-semibold">
            Customers
          </p>
          <div className="-space-y-1">
            <p className="font-semibold text-5xl">Customer</p>
            <p className="font-semibold text-5xl">Obsession.</p>
          </div>

          {/* Cards */}
          <div className="gap-y-10 gap-x-8 flex flex-col lg:flex-row">
            <Dialog>
              <DialogTrigger asChild className="lg:w-5/12">
                <div className="bg-cyan-100 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                  <p className="text-lg font-medium opacity-70 px-8">
                    Communicate
                  </p>
                  <div className="space-y-4">
                    <div className="p-10">
                      <motion.div
                        initial={{ y: -90, opacity: 0, scale: 0.96 }}
                        animate={{
                          y: [-90, 0, 0, -90, -90],
                          opacity: [0, 1, 1, 0, 0],
                          scale: [0.96, 1, 1, 0.96, 0.96],
                        }}
                        transition={{
                          duration: 5,
                          times: [0, 0.1, 0.7, 0.95, 1],
                          repeat: Infinity,
                          ease: [
                            [0.25, 0.1, 0.25, 1],
                            "linear",
                            [0.25, 0.1, 0.25, 1],
                            "linear",
                          ],
                        }}
                      >
                        <motion.img
                          src="https://i.imgur.com/bbWPvJt.png"
                          alt="Live App Notifications"
                          className="w-full filter drop-shadow-[0_10px_15px_rgba(11,42,53,0.6)]"
                        />
                      </motion.div>
                    </div>
                    <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                      Live App Notifications.
                    </p>
                  </div>
                  <div className="place-self-end flex justify-end w-full px-8">
                    <button className="bg-background rounded-full p-1.5">
                      <Plus
                        className="w-7 h-7 text-cyan-800"
                        enableBackground="green"
                      />
                    </button>
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="rounded-3xl">
                <DialogHeader>
                  <DialogTitle className="text-3xl">Communicate</DialogTitle>
                  <DialogDescription className="text-2xl h-full flex justify-center items-center">
                    Reach customers instantly with targeted push notifications.
                    Drive traffic during slow hours, announce flash sales, and
                    keep your brand top-of-mind with personalized messages.
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>

            <Dialog>
              <DialogTrigger asChild className="lg:w-7/12">
                <div className="bg-blue-200 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                  <p className="text-lg font-medium opacity-70 px-8">
                    Ownership
                  </p>
                  <div className="space-y-4">
                    <div className="p-10">
                      <img
                        src="https://i.imgur.com/bPhBdtp.png"
                        alt="Apple and Google Wallet"
                        className="w-1/2 mx-auto filter drop-shadow-[0_10px_15px_rgba(11,42,53,0.6)]"
                      />
                    </div>
                    <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                      Apple/Google Wallet.
                    </p>
                  </div>
                  <div className="place-self-end flex justify-end w-full px-8">
                    <button className="bg-background rounded-full p-1.5">
                      <Plus
                        className="w-7 h-7 text-blue-700"
                        enableBackground="blue"
                      />
                    </button>
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="rounded-3xl">
                <DialogHeader>
                  <DialogTitle className="text-3xl">Ownership</DialogTitle>
                  <DialogDescription className="text-2xl h-full flex justify-center items-center">
                    Store your loyalty cards in Apple and Google Wallet for
                    instant access. No more searching through apps—your rewards
                    are always one tap away.
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div className="space-y-6 scroll-mt-28" id="onboarding">
          <p className="rounded-full bg-emerald-300 text-black w-min text-base px-2.5 py-0.5 font-semibold">
            Onboarding
          </p>
          <div className="-space-y-1">
            <p className="font-semibold text-5xl">Quick setup,</p>
            <p className="font-semibold text-5xl">Instant access.</p>
          </div>

          {/* Cards */}
          <div className="gap-y-10 gap-x-8 flex flex-col lg:flex-row">
            <Dialog>
              <DialogTrigger asChild className="lg:w-7/12">
                <div className="bg-emerald-100 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                  <p className="text-lg font-medium opacity-70 px-8">Launch</p>
                  <div className="space-y-4">
                    <div className="p-10">
                      <div className="relative flex justify-center items-center">
                        <span className="text-8xl absolute -translate-x-16 -translate-y-8 opacity-20 rotate-12">
                          🚀
                        </span>
                        <span className="text-[10rem] relative z-10 hover:scale-110 transition-transform">
                          ⚡️
                        </span>
                        <span className="text-8xl absolute translate-x-16 translate-y-8 opacity-20 -rotate-12">
                          ✨
                        </span>
                      </div>
                    </div>
                    <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                      Live in 15 minutes.
                    </p>
                  </div>
                  <div className="place-self-end flex justify-end w-full px-8">
                    <button className="bg-background rounded-full p-1.5">
                      <Plus
                        className="w-7 h-7 text-emerald-800"
                        enableBackground="green"
                      />
                    </button>
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="rounded-3xl">
                <DialogHeader>
                  <DialogTitle className="text-3xl">Launch</DialogTitle>
                  <DialogDescription className="text-2xl h-full flex justify-center items-center">
                    Sign up, customize your rewards, and start accepting punches
                    today. No technical expertise required-we handle everything.
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>

            <Dialog>
              <DialogTrigger asChild className="lg:w-5/12">
                <div className="bg-green-200 rounded-xl flex flex-col justify-between py-8 space-y-5 overflow-clip">
                  <p className="text-lg font-medium opacity-70 px-8">Support</p>
                  <div className="space-y-4">
                    <div className="p-10">
                      <SupportScreen />
                    </div>
                    <p className="text-4xl opacity-70 text-center font-semibold text-balance px-8">
                      Expert Guidance.
                    </p>
                  </div>
                  <div className="place-self-end flex justify-end w-full px-8">
                    <button className="bg-background rounded-full p-1.5">
                      <Plus
                        className="w-7 h-7 text-green-700"
                        enableBackground="green"
                      />
                    </button>
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="rounded-3xl">
                <DialogHeader>
                  <DialogTitle className="text-3xl">Support</DialogTitle>
                  <DialogDescription className="text-2xl h-full flex justify-center items-center">
                    Get personalized support from our customer success team. We
                    ensure your loyalty program drives real results.
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <Separator />

        <div className="space-y-8">
          <p className="text-balance font-semibold text-4xl text-center">
            Some other bits and pieces...
          </p>

          <div className="flex flex-wrap justify-center items-center gap-x-4 gap-y-4">
            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">📍</span>
              <span className="font-medium">Multi-Location</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">🔔</span>
              <span className="font-medium">Notifications</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">👊</span>
              <span className="font-medium">Universal Punchcode</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">🌎</span>
              <span className="font-medium">Geolocation</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">⚡️</span>
              <span className="font-medium">Live Updates</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">🔒</span>
              <span className="font-medium">Data Encryption</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">🍎</span>
              <span className="font-medium">Apple Signin</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">Ｇ</span>
              <span className="font-medium">Google Signin</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">💮</span>
              <span className="font-medium">Unlimited Punches</span>
            </div>

            <div className="space-x-1.5 border py-1.5 px-2 rounded-lg">
              <span className="grayscale">💻</span>
              <span className="font-medium">API Access</span>
            </div>
          </div>
        </div>

        <Separator />

        <div className="space-y-2 scroll-mt-28" id="case-studies">
          <p className="text-balance font-semibold text-5xl @min-lg:bg-black">
            Case Studies
          </p>
          <p className="opacity-70">Real results from Real businesses.</p>
        </div>

        <div className="gap-y-12 gap-x-8 flex flex-col lg:flex-row">
          <div className="space-y-1.5">
            <a href="https://portoberry.com" className="block">
              <img
                src="https://static.wixstatic.com/media/fc8ab2_f017b950272f466e9043bea9562c96dd~mv2.jpg/v1/fill/w_3092,h_1232,al_c,q_90,usm_0.66_1.00_0.01,enc_avif,quality_auto/fc8ab2_f017b950272f466e9043bea9562c96dd~mv2.jpg"
                alt="Sandwich bar case study"
                className="w-full h-44 object-cover rounded-2xl"
              />
            </a>
            <p className="opacity-50 font-medium">40% More Return Visits</p>
            <p className="font-semibold text-3xl">Sandwhich Bar</p>
            <p className="font-medium">
              Portoberry replaced paper cards with PunchPocket and saw monthly
              visits surge. Customer spending increased by 25% with streamlined
              digital rewards.
            </p>
          </div>

          <div className="space-y-1.5">
            <a href="https://labrioche.co.uk" className="block">
              <img
                src="https://hampsteadvillagelondon.com/wp-content/uploads/1/la-brioche-FECT.webp"
                alt="La Brioche cafe case study"
                className="w-full h-44 object-cover rounded-2xl"
              />
            </a>
            <p className="opacity-50 font-medium">$2,400 Monthly Savings</p>
            <p className="font-semibold text-3xl">Cafè</p>
            <p className="font-medium">
              Multi-location retailer La Brioche switched to PunchPocket and cut
              overhead instantly. Digital insights helped optimize rewards,
              driving 30% higher redemption rates.
            </p>
          </div>
        </div>
      </main>

      <footer className="mt-20">
        {/* Purple wave SVG separator */}
        <div className="w-full overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            className="w-full"
          >
            <path
              fill="#9333EA"
              fillOpacity="0.2"
              d="M0,64L60,69.3C120,75,240,85,360,80C480,75,600,53,720,48C840,43,960,53,1080,58.7C1200,64,1320,64,1380,64L1440,64L1440,100L1380,100C1320,100,1200,100,1080,100C960,100,840,100,720,100C600,100,480,100,360,100C240,100,120,100,60,100L0,100Z"
            ></path>
          </svg>
        </div>

        {/* Footer content */}
        <div className="bg-gradient-to-br from-purple-100 via-purple-200 to-fuchsia-200 pt-12 pb-8 px-6 md:px-10">
          <div className="max-w-6xl mx-auto">
            {/* Footer top section with logo and CTAs */}
            <div className="flex flex-col md:flex-row items-center justify-between gap-8 mb-12">
              <div className="flex flex-col items-center md:items-start">
                <div className="text-3xl font-bold text-purple-900 mb-2 font-poetsen">
                  PunchPocket
                </div>
                <p className="text-purple-800/70 max-w-xs text-center md:text-left">
                  Digital loyalty solutions that create real connection with
                  your customers
                </p>
              </div>

              <div className="flex flex-col sm:flex-row gap-4">
                <a
                  href="mailto:hello@punchpocket.com"
                  className="cursor-pointer bg-gradient-to-r from-purple-900 to-purple-700 text-white font-semibold px-6 py-3 rounded-lg shadow-lg shadow-purple-900/30 hover:shadow-purple-900/40 hover:-translate-y-0.5 transition-all inline-block text-center"
                >
                  Get Started
                </a>
                <a
                  href="mailto:hello@punchpocket.com"
                  className="cursor-pointer bg-white/70 backdrop-blur border-2 border-purple-900/20 text-purple-900 font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-purple-50 hover:border-purple-900/30 transition-all inline-block text-center"
                >
                  Book Demo
                </a>
              </div>
            </div>

            {/* Footer links section */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-10 mb-12">
              <div>
                <h4 className="font-semibold text-lg mb-4 text-purple-900">
                  Platform
                </h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#features"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Security
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      API
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-lg mb-4 text-purple-900">
                  Resources
                </h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#case-studies"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Case Studies
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Knowledge Base
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Demo Videos
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-lg mb-4 text-purple-900">
                  Company
                </h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Media Kit
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-lg mb-4 text-purple-900">
                  Legal
                </h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hello@punchpocket.com"
                      className="text-purple-800/70 hover:text-purple-900 transition-colors"
                    >
                      GDPR
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Social and newsletter */}
            <div className="flex flex-col md:flex-row gap-10 items-center justify-between border-t border-purple-300/30 pt-8 pb-4">
              <div className="flex gap-6">
                <a
                  href="mailto:hello@punchpocket.com"
                  className="text-purple-800 hover:text-purple-900 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6"
                  >
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </a>
                <a
                  href="mailto:hello@punchpocket.com"
                  className="text-purple-800 hover:text-purple-900 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6"
                  >
                    <path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497-3.753C20.18 7.773 21.692 5.25 22 4.009z"></path>
                  </svg>
                </a>
                <a
                  href="mailto:hello@punchpocket.com"
                  className="text-purple-800 hover:text-purple-900 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                  </svg>
                </a>
              </div>

              <div className="flex items-center">
                <div className="relative flex items-center">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="py-2 px-4 pr-28 rounded-full border-2 border-purple-200 focus:border-purple-400 focus:outline-none bg-white/70 backdrop-blur w-full md:w-auto"
                  />
                  <button className="absolute right-1 bg-gradient-to-r from-purple-900 to-purple-700 text-white text-sm font-medium py-1.5 px-4 rounded-full">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="text-center text-purple-800/60 text-sm mt-8">
              <p>
                © 2025 PunchPocket. All rights reserved. Made with 💜 for small
                businesses.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

function HeroSection() {
  return (
    <>
      {/* Mobile */}
      <div className="mt-10 px-8 md:px-30 lg:hidden bg-gradient-to-b from-white via-purple-200 to-white">
        <div
          style={{ position: "relative", paddingTop: "56.25%" }}
          className="w-full shadow-2xl shadow-purple-900/80 rounded overflow-clip"
        >
          <iframe
            title="PunchPocket Demo"
            src="https://customer-pbblgh03drve2rrb.cloudflarestream.com/062297498d71aeb573a15ad99400ac96/iframe?preload=true&poster=https%3A%2F%2Fcustomer-pbblgh03drve2rrb.cloudflarestream.com%2F062297498d71aeb573a15ad99400ac96%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D20s%26height%3D600"
            loading="lazy"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          ></iframe>
        </div>
      </div>
      {/* Desktop */}
      <div className="hidden lg:block pb-18 overflow-x-clip">
        <div className="mt-30 flex items-center justify-center gap-6 bg-gradient-to-b from-white via-purple-200 to-white">
          {/* Row 1 Left */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: [0.25, 0.1, 0.25, 1.0] }}
          >
            <motion.img
              src="https://i.imgur.com/obUY1Qv.png"
              className="rotate-[-13deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 400 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/Mm9Tdbb.png"
              className="translate-x-1 rotate-[30deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 400 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.2,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/od4x6FN.png"
              className="rotate-[-10deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 400 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>

          {/* Row 2 Left */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.1,
              ease: [0.25, 0.1, 0.25, 1.0],
            }}
          >
            <motion.img
              src="https://i.imgur.com/A6I5vbu.png"
              className="rotate-[5deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.2,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/iQRMlp0.png"
              className="rotate-[18deg] w-24 h-full 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/ATJL8VW.png"
              className="rotate-[-20deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.4,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/pqN8zSS.png"
              className="rotate-[-9deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.5,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>

          {/* Row 3 Left */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.2,
              ease: [0.25, 0.1, 0.25, 1.0],
            }}
          >
            <motion.img
              src="https://i.imgur.com/qqnmYSU.png"
              className="rotate-[13deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/ES69BBI.png"
              className="rotate-[-20deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.4,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>

          {/* Hero Image */}
          <motion.div
            className="w-full shadow-2xl shadow-purple-900/80 rounded overflow-clip max-w-5xl scale-140 xl:scale-110 z-10 bg-purple-950/90"
            style={{
              position: "relative",
              aspectRatio: "16/9",
            }}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.3,
              delay: 0.4,
              ease: [0.25, 0.1, 0.25, 1.0],
            }}
          >
            <iframe
              title="PunchPocket Demo"
              src="https://customer-pbblgh03drve2rrb.cloudflarestream.com/062297498d71aeb573a15ad99400ac96/iframe?preload=true&poster=https%3A%2F%2Fcustomer-pbblgh03drve2rrb.cloudflarestream.com%2F062297498d71aeb573a15ad99400ac96%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D20s%26height%3D600"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              loading="lazy"
              allowFullScreen
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            ></iframe>
          </motion.div>

          {/* Row 1 Right */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.2,
              ease: [0.25, 0.1, 0.25, 1.0],
            }}
          >
            <motion.img
              src="https://i.imgur.com/obUY1Qv.png"
              className="rotate-[-13deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/Mm9Tdbb.png"
              className="rotate-[20deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.4,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>

          {/* Row 2 Right */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.1,
              ease: [0.25, 0.1, 0.25, 1.0],
            }}
          >
            <motion.img
              src="https://i.imgur.com/qqnmYSU.png"
              className="rotate-[-5deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.2,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/A6I5vbu.png"
              className="-translate-y-1 rotate-[17deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/fczWLhA.png"
              className="rotate-[20deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.4,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/iQRMlp0.png"
              className="rotate-[9deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.5,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>

          {/* Row 3 Right */}
          <motion.div
            className="flex flex-col gap-6 shrink-0"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: [0.25, 0.1, 0.25, 1.0] }}
          >
            <motion.img
              src="https://i.imgur.com/ATJL8VW.png"
              className="rotate-[-20deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -400 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/pqN8zSS.png"
              className="rotate-[13deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -400 }}
              animate={{ x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.2,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />

            <motion.img
              src="https://i.imgur.com/ES69BBI.png"
              className="translate-x-1 rotate-[-30deg] w-24 2xl:w-32 rounded shadow-2xl shadow-purple-900/80 border border-purple-900/50"
              initial={{ x: -400, y: -150 }}
              animate={{ x: 0, y: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0],
              }}
            />
          </motion.div>
        </div>
      </div>
    </>
  );
}

const TitleBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="top-0 z-50 w-full bg-white/80 backdrop-blur-sm shadow-sm">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          {/* Logo */}
          <div className="flex items-center">
            <div className="text-2xl font-bold text-purple-900 font-poetsen">
              PunchPocket
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <a
              href="#features"
              className="font-medium text-gray-700 hover:text-purple-900 transition-colors"
            >
              Features
            </a>
            <a
              href="#onboarding"
              className="font-medium text-gray-700 hover:text-purple-900 transition-colors"
            >
              Onboarding
            </a>
            <a
              href="#case-studies"
              className="font-medium text-gray-700 hover:text-purple-900 transition-colors"
            >
              Case Studies
            </a>
            <a
              href="mailto:hello@punchpocket.com"
              className="rounded-lg bg-gradient-to-r from-purple-900 to-purple-700 px-4 py-2 font-semibold text-white shadow-md hover:shadow-lg hover:-translate-y-0.5 transition-all duration-150 inline-block"
            >
              Get Started
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-purple-900 hover:bg-purple-100 focus:outline-none"
              onClick={toggleMenu}
            >
              <span className="sr-only">
                {isMenuOpen ? "Close menu" : "Open menu"}
              </span>
              {isMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <motion.div
          className="md:hidden"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="divide-y divide-gray-200 border-b border-gray-200 bg-white px-4 pb-6 pt-2">
            <div className="space-y-1 pb-3">
              <a
                href="#features"
                className="block py-2 font-medium text-purple-900 hover:bg-purple-50 rounded-md px-3"
                onClick={() => setIsMenuOpen(false)}
              >
                Features
              </a>
              <a
                href="#onboarding"
                className="block py-2 font-medium text-purple-900 hover:bg-purple-50 rounded-md px-3"
                onClick={() => setIsMenuOpen(false)}
              >
                Onboarding
              </a>
              <a
                href="#case-studies"
                className="block py-2 font-medium text-purple-900 hover:bg-purple-50 rounded-md px-3"
                onClick={() => setIsMenuOpen(false)}
              >
                Case Studies
              </a>
            </div>
            <div className="pt-4">
              <a
                href="mailto:hello@punchpocket.com"
                className="w-full rounded-lg bg-gradient-to-r from-purple-900 to-purple-700 px-4 py-2 font-semibold text-white shadow-md hover:shadow-lg hover:-translate-y-0.5 transition-all duration-150 inline-block text-center"
              >
                Get Started
              </a>
              <a
                href="mailto:hello@punchpocket.com"
                className="mt-4 w-full rounded-lg border border-purple-200 bg-white px-4 py-2 font-semibold text-purple-900 inline-block text-center"
              >
                Book Demo
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

function Scanner() {
  const [animationState, setAnimationState] = useState("idle");

  // Auto-loop the animation
  useEffect(() => {
    // Start the first animation after component mounts
    const timeoutId = setTimeout(() => {
      startAnimation();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const startAnimation = () => {
    // Reset animation state
    setAnimationState("idle");

    // Start success animation after a short delay
    setTimeout(() => {
      setAnimationState("success");

      // Reset after animation completes and start the next cycle
      setTimeout(() => {
        setAnimationState("idle");

        // Add delay between animation cycles
        setTimeout(() => {
          startAnimation();
        }, 1500);
      }, 2000);
    }, 500);
  };

  // Circle animation variants
  const circleVariants = {
    idle: {
      scale: 0,
      opacity: 0,
      borderColor: "#E5E7EB",
    },
    success: {
      scale: 1,
      opacity: 1,
      borderColor: "#10B981",
      transition: {
        scale: { duration: 0.5, ease: "easeOut" },
        opacity: { duration: 0.5, ease: "easeOut" },
        borderColor: { duration: 0.3, delay: 0.2 },
      },
    },
  };

  // Checkmark variants
  const checkVariants = {
    idle: {
      pathLength: 0,
      opacity: 0,
    },
    success: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="relative w-full">
      <img
        src="https://i.imgur.com/9QZM48C.png"
        alt="Scan, Punch, Done."
        className="w-full opacity-70 rounded-2xl shadow-2xl shadow-violet-900"
      />

      <div className="absolute inset-0 -top-1/3 flex items-center justify-center">
        <div className="relative flex items-center justify-center w-40 h-40">
          <motion.div
            className="w-32 h-32 rounded-full border-4 flex items-center justify-center"
            variants={circleVariants}
            initial="idle"
            animate={animationState}
          >
            {/* Success checkmark */}
            <svg
              className="w-20 h-20 text-green-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <motion.path
                d="M5 13l4 4L19 7"
                variants={checkVariants}
                initial="idle"
                animate={animationState}
              />
            </svg>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

const TypewriterText = ({ text }: { text: string }) => {
  const [displayText, setDisplayText] = useState("");
  const [direction, setDirection] = useState("typing"); // 'typing', 'holding', 'erasing'
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (direction === "typing" && charIndex < text.length) {
      // Typing effect
      timer = setTimeout(() => {
        setDisplayText(text.substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }, 50); // Speed of typing
    } else if (direction === "typing" && charIndex >= text.length) {
      // Switch to holding state when typing is complete
      setDirection("holding");
      setCharIndex(text.length);
    } else if (direction === "holding") {
      // Hold the complete text for 2 seconds
      timer = setTimeout(() => {
        setDirection("erasing");
      }, 2000);
    } else if (direction === "erasing" && charIndex > 0) {
      // Erasing effect
      timer = setTimeout(() => {
        setDisplayText(text.substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
      }, 30); // Speed of erasing (slightly faster than typing)
    } else if (direction === "erasing" && charIndex <= 0) {
      // Reset to typing after erasing is complete
      timer = setTimeout(() => {
        setDirection("typing");
        setCharIndex(0);
      }, 1000); // Pause before starting again
    }

    return () => clearTimeout(timer);
  }, [text, charIndex, direction]);

  return (
    <span>
      {displayText}
      <motion.span
        animate={{ opacity: [1, 0] }}
        transition={{
          repeat: Infinity,
          duration: 0.8,
          ease: "linear",
          repeatType: "reverse",
        }}
        style={{ visibility: direction === "holding" ? "hidden" : "visible" }}
      >
        |
      </motion.span>
    </span>
  );
};

function SupportScreen() {
  return (
    <div className="bg-white/80 rounded-xl p-4 shadow-sm">
      <div className="flex items-start gap-3 mb-4">
        <div className="bg-green-100 p-1 rounded-full">
          <img
            src="https://i.imgur.com/8VfNvOc.png"
            alt="Support Agent Sarah"
            className="w-8 h-8 rounded-full object-cover"
          />
        </div>
        <div className="flex-1">
          <p className="font-medium text-sm">Sarah</p>
          <p className="text-sm opacity-50">PunchPocket Support</p>
        </div>
      </div>
      <div className="space-y-2">
        <div className="bg-green-50 rounded-lg p-3 text-sm font-medium">
          Hi! I&apos;d be happy to help you set up your rewards program! 👋
        </div>
        <div className="bg-green-50 rounded-lg p-3 text-sm h-[60px]">
          <TypewriterText text="Let me guide you through the process step by step ✨" />
        </div>
      </div>
    </div>
  );
}
